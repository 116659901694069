import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import React from 'react'
import { Sticky } from 'react-sticky'
import EasySoftwareSearch from 'Shared/components/EasySoftwareSearch/EasySoftwareSearch'
import PuxButton, {
  PuxButtonColor,
  PuxButtonStyle,
} from 'Shared/components/PuxButton/PuxButton'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import PuxLanguageSelector, {
  localizedPathType,
} from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { LinkType } from 'Shared/types/Link'
import { getFromConfig, getLocalizedUrl } from 'Shared/utils/localeURL'
import { PuxAnchorLink } from 'Shared/utils/PuxAnchorLink/PuxAnchorLink'
import { t } from 'ttag'

import { useHeaderData } from '../../queries/_use-header-data.js'
import { ReactComponent as Logo } from '../../svg/logo-project.svg'
import IconClose from '../../svg/mobile-menu-off.svg'
import IconBurger from '../../svg/mobile-menu-on.svg'
import HeaderNav, { HeaderData, renderData } from './HeaderNav'

const rootClass = `header`
const localizedHomepageUrl = `/${getFromConfig(
  process.env.LOCALE ?? `en`,
  `urlPrefix`,
  `EasyProjectGatsby`
)}`

interface HeaderPropsType {
  customHeaderClass?: string
  customHeaderFnc: (duplicatedNav: boolean) => void
  style?: string
  cta?: LinkType
  landingPageNav?: HeaderData
  localizedPaths?: localizedPathType[]
}

const Header: React.FunctionComponent<HeaderPropsType> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isLanguageDialogOpen, setLanguageDialogOpen] = React.useState(false)
  const headerData: HeaderData = useHeaderData()
  const location = useLocation()
  const anchorPath = location.pathname
  const localizedPaths = props.localizedPaths

  const hasDuplicatedNavigation = (): boolean => {
    const duplicatedNavArray: boolean[] = []

    if (props.landingPageNav) {
      return false
    }

    headerData.menuItems.map((menuItem) => {
      const renderData: renderData = JSON.parse(menuItem.render)

      if (location.pathname === getLocalizedUrl(renderData.url)) {
        duplicatedNavArray.push(
          menuItem.menuItemsList != null &&
          !!menuItem.menuItemsList.menuItems.length
        )
      } else {
        if (
          menuItem.menuItemsList != null &&
          menuItem.menuItemsList.menuItems.length
        ) {
          const categoryItems = menuItem.menuItemsList.menuItems
          categoryItems.map((categoryItem) => {
            categoryItem.menuItemsList.menuItems.map((submenuItem) => {
              const submenuRenderData: renderData = submenuItem?.render
                ? JSON.parse(submenuItem.render)
                : {
                  url: submenuItem?.linkMenuItem?.url,
                  name: submenuItem?.linkMenuItem?.name,
                  internal: false,
                }
              duplicatedNavArray.push(
                location.pathname === getLocalizedUrl(submenuRenderData.url)
              )
            })
          })
        } else {
          duplicatedNavArray.push(false)
        }
      }
    })

    const duplicatedNav = duplicatedNavArray.includes(true)
    return duplicatedNav
  }

  const hasDuplicatedNav = hasDuplicatedNavigation()
  props.customHeaderFnc(hasDuplicatedNav)

  return (
    <div
      className={`${hasDuplicatedNav ? `has-megamenu` : ``} ${props.customHeaderClass ?? ``
        }`}
    >
      <Sticky disableCompensation>
        {({
          distanceFromTop,
        }: {
          style: React.CSSProperties
          distanceFromTop: number
        }) => (
          <header
            className={`${isOpen ? `open ` : ``}${distanceFromTop < 0 ? `scrolled` : ``
              }`}
          >
            <div className='pux-container'>
              <div
                className={`${rootClass} ${props.style === `landing` ? rootClass + `-landing` : ``
                  }`}
              >
                <div className={`${rootClass}-logo`}>
                  <Link to={localizedHomepageUrl}>
                    <Logo />
                  </Link>
                </div>

                {props.style === `landing` && props.cta && (
                  <div className={`${rootClass}-cta`}>
                    <PuxButton
                      buttonType={PuxButtonStyle.FILLED}
                      buttonColor={PuxButtonColor.CONVERSION}
                      buttonLink={{
                        url: [props.cta.url],
                        internal: [false],
                        text: [props.cta.text],
                      }}
                    />
                  </div>
                )}

                {/* {props.style === `landing` && (
                  <div className={`${rootClass}-home`}>
                    <PuxButton
                      buttonType={PuxButtonStyle.LINK}
                      buttonColor={PuxButtonColor.PRIMARY}
                      buttonLink={{
                        url: [`/`],
                        internal: [true],
                        text: [`Easy Redmine`],
                      }}
                    />
                  </div>
                )} */}

                <div className='header-trial'>
                  <PuxAnchorLink
                    to={props.cta ? props.cta.url : `${anchorPath}#trial`}
                    className='pux-button pux-button-filled pux-button-conversion'
                  >
                    {props.cta ? props.cta.text : t`Redmine.Header.FreeTrial`}
                  </PuxAnchorLink>
                </div>
                <div
                  className={`${rootClass}-burger`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span className='open'>
                    <img src={IconBurger} width={20} height={20} />
                  </span>
                  <span className='close'>
                    <img src={IconClose} width={20} height={20} />
                  </span>
                </div>
                <HeaderNav
                  headerData={
                    props.landingPageNav ? props.landingPageNav : headerData
                  }
                  setLanguageDialogOpen={setLanguageDialogOpen}
                />
                <a
                  href='https://client-zone.easysoftware.com/en/login'
                  className={`${rootClass}-user`}
                >
                  <PuxIconBuilder
                    icon='UserIcon'
                    options={{
                      color: {
                        colorPrimary: `#0080ff`,
                        colorSecondary: `#50596f`,
                      },
                      hoverColor: {
                        colorPrimary: `#0080ff`,
                        colorSecondary: `#50596f`,
                      },
                    }}
                  />
                  <span>{t`Redmine.Header.User`}</span>
                </a>
                <EasySoftwareSearch
                  searchType='header'
                  setLanguageDialogOpen={setLanguageDialogOpen}
                />
                <div className={`${rootClass}-language`}>
                  <PuxLanguageSelector
                    isOpen={isLanguageDialogOpen}
                    setOpen={setLanguageDialogOpen}
                    localizedPaths={localizedPaths}
                  />
                </div>
              </div>
            </div>
          </header>
        )}
      </Sticky>
      <div
        className={`after-header${hasDuplicatedNavigation() ? ` has-megamenu` : ``
          }`}
      ></div>
    </div>
  )
}

export default Header
